export const contract = {
  list: '套餐列表',
  from: '套餐操作',
  info: '套餐费用',

  clause_info: '条款信息',

  title: '套餐名称',
  description: '套餐描述',
  content: '套餐详情',
  money: '套餐金额',
  pay_money: '支付金额',
  original_money: '原始价格',
  period: '套餐周期',
  value: '套餐天数',
  rate: '优惠折扣',

  rules: {
    title: {
      require: '套餐名称不能为空',
    },
    money: {
      require: '套餐金额不能为空',
    },
    pay_money: {
      require: '支付金额不能为空',
    },
    original_money: {
      require: '原始价格不能为空',
    },
    period: {
      require: '套餐周期不能为空',
    },
    value: {
      require: '套餐天数不能为空',
    },
  },

  // 套餐分类
  category: {
    list: '套餐分类列表',
    from: '套餐分类操作',

    picture: '套餐分类图片',
    title: '套餐分类标题',
    language: '套餐分类语言',

    rules: {
      picture: {
        require: '套餐分类图片不能为空',
      },
      title: {
        require: '套餐分类标题不能为空',
      },
    },
  },

  // 套餐条款
  authority: {
    list: '套餐条款列表',
    from: '套餐条款操作',
    info: '套餐条款',

    picture: '套餐条款图片',
    title: '套餐条款标题',

    rules: {
      picture: {
        require: '套餐条款图片不能为空',
      },
      title: {
        require: '套餐条款标题不能为空',
      },
    },
  },

  // 套餐条款
  clause: {
    list: '套餐条款列表',
    from: '套餐条款操作',
    info: '条款',

    name: '条款名称',
    title: '条款标题',
    value: '条款内容',
    description: '条款说明',

    rules: {
      name: {
        require: '条款名称不能为空',
      },
      title: {
        require: '条款标题不能为空',
      },
      value: {
        require: '条款内容不能为空',
      },
    },
  },
}
